import { getAddress } from 'ethers'
import { ADMIN_WHITELIST_ADDRESS } from '@/constants'
import type { Currency } from '@nnmax/uniswap-sdk-v2'

export function isAdmin(address: string | undefined | null) {
  if (!address) return false
  return ADMIN_WHITELIST_ADDRESS.findIndex((whiteItem) => whiteItem.toLowerCase() === address.toLowerCase()) !== -1
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function isETHToken(token: Currency | string | undefined) {
  if (!token) return false
  if (typeof token === 'string') {
    return !isAddress(token)
  }
  return token.symbol === 'ETH'
}

export function isDOGToken(token: Currency | undefined) {
  if (!token) return false
  return token.symbol === 'DOG'
}

export function isWETHToken(token: Currency | undefined) {
  if (!token) return false
  return token.symbol === 'WETH'
}

/**
 * Returns true if the string value is zero in hex
 */
export function isZero(hexNumberString: string) {
  return /^0x0*$/.test(hexNumberString)
}
