/* eslint-disable react-refresh/only-export-components */
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { lazy } from 'react'
import Root from '@/root'

const NotFound = lazy(() => import('@/pages/404'))
const Admin = lazy(() => import('@/pages/Admin'))
const Asset = lazy(() => import('@/pages/Asset'))
const Deposit = lazy(() => import('@/pages/Asset/deposit'))
const Withdraw = lazy(() => import('@/pages/Asset/withdraw'))
const Earn = lazy(() => import('@/pages/Earn'))
const PoolAdd = lazy(() => import('@/pages/Pool/add'))
const PoolAll = lazy(() => import('@/pages/Pool/all'))
const PoolMy = lazy(() => import('@/pages/Pool/my'))
const PoolRemove = lazy(() => import('@/pages/Pool/remove'))
const Swap = lazy(() => import('@/pages/Swap'))

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        index: true,
        element: <Swap />,
      },
      {
        path: '/pool',
        element: <Navigate to={'/pool/all'} />,
      },
      {
        path: '/pool/all',
        element: <PoolAll />,
      },
      {
        path: '/pool/my',
        element: <PoolMy />,
      },
      {
        path: '/pool/add/:currencyIdA/:currencyIdB',
        element: <PoolAdd />,
      },
      {
        path: '/pool/remove/:currencyIdA/:currencyIdB',
        element: <PoolRemove />,
      },
      {
        path: '/asset',
        element: <Asset />,
      },
      {
        path: '/asset/withdraw',
        element: <Withdraw />,
      },
      {
        path: '/asset/deposit',
        element: <Deposit />,
      },
      {
        path: '/earn',
        element: <Earn />,
      },
      {
        path: '/__admin',
        element: <Admin />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
])

export default router
