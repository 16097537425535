export default function Failed(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      version={'1.1'}
      width={'36'}
      height={'36'}
      viewBox={'0 0 36 36'}
      {...props}
    >
      <g>
        <g>
          <rect x={'0'} y={'0'} width={'36'} height={'36'} rx={'18'} fill={'#FA5151'} fillOpacity={'1'} />
        </g>
        <g>
          <path
            d={
              'M12.28571,12.28572L14.57142,12.28572L14.57142,14.57143L12.28571,14.57143L12.28571,12.28572ZM10,10L12.28571,10L12.28571,12.28571L10,12.28571L10,10ZM10,23.7143L12.28571,23.7143L12.28571,26L10,26L10,23.7143ZM23.7143,23.7143L26,23.7143L26,26L23.7143,26L23.7143,23.7143ZM14.57141,14.57143L16.857129999999998,14.57143L16.857129999999998,16.85714L14.57141,16.85714L14.57141,14.57143ZM16.857120000000002,16.85715L19.14283,16.85715L19.14283,19.14286L16.857120000000002,19.14286L16.857120000000002,16.85715ZM19.142879999999998,19.14285L21.4286,19.14285L21.4286,21.4286L19.142879999999998,21.4286L19.142879999999998,19.14285ZM21.4286,21.4286L23.7143,21.4286L23.7143,23.7143L21.4286,23.7143L21.4286,21.4286ZM23.7143,12.28572L21.4286,12.28572L21.4286,14.57143L23.7143,14.57143L23.7143,12.28572ZM26,10L23.7143,10L23.7143,12.28571L26,12.28571L26,10ZM21.4286,14.57143L19.142870000000002,14.57143L19.142870000000002,16.85714L21.4286,16.85714L21.4286,14.57143ZM16.857120000000002,19.14285L14.5714,19.14285L14.5714,21.4286L16.857120000000002,21.4286L16.857120000000002,19.14285ZM14.57141,21.4286L12.2857,21.4286L12.2857,23.7143L14.57141,23.7143L14.57141,21.4286Z'
            }
            fill={'#FFFFFF'}
            fillOpacity={'1'}
          />
        </g>
      </g>
    </svg>
  )
}
