export default function LogoBox(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      version={'1.1'}
      width={'38'}
      height={'38'}
      viewBox={'0 0 38 38'}
      {...props}
    >
      <defs>
        <clipPath id={'master_svg0_986_99200'}>
          <rect x={'0'} y={'0'} width={'38'} height={'38'} rx={'0'} />
        </clipPath>
      </defs>
      <g clipPath={'url(#master_svg0_986_99200)'}>
        <g>
          <path
            d={
              'M19.063313427734375,1.393341064453125L4.801913427734375,9.626081064453125L10.329593427734375,12.819741064453124L24.347813427734376,4.443321064453125L19.063313427734375,1.393341064453125ZM28.371913427734373,6.797121064453125L14.375813427734375,15.151441064453126L19.151813427734375,17.914041064453126L33.42421342773437,9.681341064453125L28.371913427734373,6.797121064453125ZM8.682363427734375,23.572041064453124L12.817073427734375,26.036341064453126L12.817073427734375,17.328441064453123L17.725613427734373,20.168441064453123L17.725613427734373,36.73334106445313L3.420013427734375,28.478541064453125L3.420013427734375,11.913541064453126L8.770803427734375,15.007741064453125L8.682363427734375,23.572041064453124ZM20.401013427734377,36.73334106445313L20.401013427734377,20.267841064453126L34.70671342773437,12.013041064453125L34.70671342773437,28.478441064453126L20.401013427734377,36.73334106445313Z'
            }
            fillRule={'evenodd'}
            fill={'#FFC300'}
            fillOpacity={'1'}
          />
        </g>
      </g>
    </svg>
  )
}
